<template>
    <div id="pdf-layout" class="">
        <basic></basic>
    </div>
</template>

<script>
import Basic from '@/tba/views/pdf/templates/Basic'
export default {
  name: 'pdf-layout',
  components: {
    Basic 
  },
  data () {
    return {
      // 
    }
  },
  created (){ 
  },
  mounted () {
  },
  updated () {
    // 
  }
}
</script>

<style >
    
</style>
