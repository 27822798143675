<template>
    <div id="basic" class="" style="background:white; position: absolute; top:0; left:0;right:0;bottom:0;">
      <div class="page">
        <main class="header invoice-box">
            <div class="type-invoice">
                <div class="type-box">
                    <div class="title">FACTURe</div>
                    <div class="detail">de vente à l'exportation</div>
                </div>

                <div class="type-invoice-details">
                    <div class="type-facture_details">Facture N°: 1211052</div>
                    <div class="type-facture_details">Date: 2021-05-22</div> 
                </div>
                
            </div>
            <div class="company">
                <span class="ct1" >Information Basique</span>
                <div class="name">
                    TEC SARl
                </div>
                <div class="details">Cotonou, Agontikon</div>
                <div class="details">1258745896587</div>
                <div class="details">RB COT/2021/05</div>
                <br>
                <div class="details">(229) 90 018 555</div>
                <div class="details">support@tec-sarl.com</div>
            </div>
        </main>
        <main class="info-customer-payment invoice-box pt-30">
            <div class="customer">
                <span class="ct2" >Facture à:</span>
                <div class="name">
                    Phoenix Petroleum
                </div>
                <div class="details">Cotonou, Avenue Steimetz</div>
                <div class="details">1258745896587</div>
                <div class="details">RB COT/2081/05</div>
                <br>
                <div class="details">(229) 21 555 888</div>
                <div class="details">support@phoenix.com</div>
           </div>

           <div class="otherinfo">
               <div class="item"><span>Vendeur:</span> 1 - Franklin Ahannon</div>
               <div class="item"><span>Paiement:</span> CHEQUE - 200 000</div>
               <div class="item"><span>Reference Commande:</span> à calavi 985</div>
               <div class="item"><span>Reference Commande:</span> n° 6505985</div>
               <div class="item"><span>Reference Autres:</span> Serimade promo</div>
           </div>
        </main>

        <main class="invoice-table invoice-box pt-30 page-break-inside">
            <table>
                <thead>
                    <tr>
                        <th>REF</th>
                        <th>Désignation</th>
                        <th>Qte</th>
                        <th>Prix Unitaire</th>
                        <th>Total</th>
                    </tr>
                </thead>

                <tbody>
                    <tr>
                        <td>PMS</td>
                        <td>Essence [ B ]</td>
                        <td>5</td>
                        <td>500</td>
                        <td>2500</td>
                    </tr>
                    <tr>
                        <td>PMS</td>
                        <td>Essence [ B ]</td>
                        <td>5</td>
                        <td>500</td>
                        <td>2500</td>
                    </tr>
                    <tr>
                        <td>PMS</td>
                        <td>Essence [ B ]</td>
                        <td>5</td>
                        <td>500</td>
                        <td>2500</td>
                    </tr>
                </tbody>
            </table>
        </main>

        <main class="invoice-sub invoice-box pt-30 page-break-inside">
            <div class="mcf">
                <div class="qr">
                    <img width="100" src="qrcode.svg" alt="">
                </div>
                <div class="details">
                    <div class="details-i">PX5-ZCC3-Q2ZY-VZOI-D2VD-DZFO</div>
                    <div class="details-i">NIM: ED04001066 </div>
                    <div class="details-i">Compteurs: 1872/2220 FV</div>
                    <div class="details-i">Heure: 04/05/2021 16:48:07</div>
                </div>
            </div>
            <div class="sub">
                <table>
                    <tr class="ttc">
                        <td>Total TTC</td>
                        <td>200 000</td>
                    </tr>
                    <tr>
                        <td>
                            <div></div>
                            <div>Total HT B</div>
                            <div>Total HT A</div>
                            <div>Total AIB</div>
                            <div>Total TS</div>
                            <div>Total TVA</div>
                        </td>
                        <td></td>
                    </tr>
                </table>
            </div>
        </main>

        <main class="invoice-bank-sign invoice-box pt-30 page-break-inside">
            <div class="bank-info">
                <h4>Banque Info</h4>
                <div class="details">
                    <div class="details-b">NSIa Bank</div>
                    <div class="details-b">Cotonou, Agontikon</div>
                    <div class="details-b">BG614561684786471867</div>
                    <div class="details-b">BG614</div>
                </div>
            </div>
            <div class="sign-info">
                <h3>Magloire Zossou</h3>
                <span>Le directeur</span>
            </div>
        </main>
      </div>
    </div>
</template>

<script>
export default {
  name: 'basic',
  components: {
    //   
  },
  data () {
    return {
      // 
    }
  },
  created (){ 
  },
  mounted () {
    document.body.style.background = "white" 
  },
  updated () {
    // 
  }
}
</script>

<style scoped>
/**@import url("https://fonts.googleapis.com/css2?family=Nunito:ital,wght@0,200;0,300;0,400;0,600;0,700;0,800;0,900;1,200;1,300;1,400;1,600;1,700;1,800;1,900&display=swap");*/
body, html {
  font-family: "Nunito", sans-serif;
}

.page-break-inside {
  page-break-inside: avoid;
}

.page-break-before {
  page-break-before: always;
}

.page-break-after {
  page-break-after: always;
}

.page {
  background: white;
  display: block;
  margin: 0 auto;
  margin-bottom: 0.5cm;
  box-shadow: 0 0 0.5cm rgba(0, 0, 0, 0.5);
}

.page[size=A4] {
  width: 21cm;
  height: 29.7cm;
}

.page[size=A4][layout=landscape] {
  width: 29.7cm;
  height: 21cm;
}

.page[size=A3] {
  width: 29.7cm;
  height: 42cm;
}

.page[size=A3][layout=landscape] {
  width: 42cm;
  height: 29.7cm;
}

.page[size=A5] {
  width: 14.8cm;
  height: 21cm;
}

.page[size=A5][layout=landscape] {
  width: 21cm;
  height: 14.8cm;
}

@media print {
  body, .page {
    margin: 0;
    box-shadow: 0;
  }
}
.invoice-box {
  padding: 30px 50px;
}

.pt-30 {
  padding-top: 30px;
}

.ct1 {
  text-decoration: underline;
  font-size: 12px;
  font-weight: 600;
}

.ct2 {
  font-size: 15px;
  font-weight: 500;
}

.header.invoice-box {
  display: flex;
  justify-content: space-between;
  min-height: 170px;
  height: 170px;
}
.header.invoice-box .type-invoice {
  flex-grow: 1;
}
.header.invoice-box .type-invoice .type-box {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: auto;
  min-height: 50px;
  color: white;
  background-color: #d81243;
  font-size: 15px;
  font-weight: 600;
  padding-bottom: 5px;
}
.header.invoice-box .type-invoice .type-box .title {
  font-weight: 700;
  letter-spacing: 2px;
  font-size: 35px;
  text-transform: uppercase;
}
.header.invoice-box .type-invoice .type-box .detail {
  margin-top: -10px;
  font-weight: 600;
  letter-spacing: 1px;
  font-size: 14px;
  text-transform: capitalize;
}
.header.invoice-box .type-invoice .type-invoice-details {
  padding-top: 8px;
  padding-left: 30px;
}
.header.invoice-box .type-invoice .type-invoice-details .type-facture_details {
  text-align: left;
  font-weight: 600;
  font-size: 14px;
}
.header.invoice-box .company {
  flex-grow: 3.5;
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  text-align: right;
  width: 300px;
}
.header.invoice-box .company .name {
  font-size: 20px;
  padding-top: 20px;
  font-weight: 700;
  text-transform: uppercase;
}
.header.invoice-box .company .details {
  font-size: 13px;
  font-weight: 300;
}

.info-customer-payment.invoice-box {
  display: flex;
  justify-content: space-between;
  height: 50px;
}
.info-customer-payment.invoice-box .customer {
  padding-left: 30px;
  margin-top: -100px;
}
.info-customer-payment.invoice-box .customer .name {
  font-weight: 600;
  font-size: 20px;
}
.info-customer-payment.invoice-box .customer .details {
  font-size: 13px;
  font-weight: 300;
}
.info-customer-payment.invoice-box .otherinfo {
  margin-top: -34px;
  font-size: 13px;
  font-weight: 300;
  text-align: justify;
}
.info-customer-payment.invoice-box .otherinfo .item span {
  text-transform: capitalize;
  font-weight: 600;
}

table {
  width: 100%;
  border-spacing: 0px;
  border-collapse: collapse;
}

.invoice-table table thead tr {
  background-color: #d81243;
}
.invoice-table table thead tr th {
  padding: 15px 21px;
  color: white;
  font-weight: 400;
}
.invoice-table table tbody tr {
  background-color: rgba(129, 125, 125, 0.075);
}
.invoice-table table tbody tr:nth-child(2n+2) {
  background-color: white;
}
.invoice-table table tbody tr td {
  padding: 20px 21px;
  text-align: center;
}

.invoice-sub.invoice-box {
  margin-top: -30px;
  display: flex;
  justify-content: space-between;
}
.invoice-sub.invoice-box .mcf {
  flex-grow: 1.5;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.invoice-sub.invoice-box .mcf .details {
  font-weight: 600;
  padding-left: 10px;
  font-size: 14px;
}
.invoice-sub.invoice-box .sub {
  flex-grow: 1;
}
.invoice-sub.invoice-box .sub table tr {
  text-align: center;
}
.invoice-sub.invoice-box .sub table tr.ttc {
  color: #d81243;
}
.invoice-sub.invoice-box .sub table tr.ttc td {
  padding: 15px 0px;
  font-weight: 800;
}
.invoice-sub.invoice-box .sub table tr td {
  background-color: #ff2c6107;
}

.invoice-bank-sign.invoice-box {
  display: flex;
  justify-content: space-between;
}
.invoice-bank-sign.invoice-box .bank-info {
  flex-grow: 1;
}
.invoice-bank-sign.invoice-box .bank-info h4 {
  text-transform: uppercase;
}
.invoice-bank-sign.invoice-box .bank-info .details {
  margin-top: -20px;
  font-size: 14px;
}
.invoice-bank-sign.invoice-box .sign-info {
  flex-grow: 1;
  padding-top: 50px;
  text-align: right;
}

/*# sourceMappingURL=style.css.map */

</style>
